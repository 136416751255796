<template>
  <div class="consensu-container"  :style="cssVars" v-if="consent.configs">
   <div class="overlay" :class="overlayClass">
      <div class="sign-in overlay-text-left-animation" id="sign-in">
         <h1>{{ consent.configs.message.title }}</h1>
         <p>{{ consent.configs.message.description }}</p>
         <div class="actions-column">
         <button class="switch-button" @click="custom" id="slide-right-button">{{ consent.configs.buttons.custom }}</button>
         <button class="switch-button consensu-mobile"  @click="saveAll">
            {{ consent.configs.buttons.accepted }}
            <i class="fa fa-circle-o-notch fa-spin" v-if="loadingAll"></i>
         </button>
          <button class="switch-button consensu-mobile" style="font-size:12px !important;"  @click="saveRequested">
           {{ consent.configs.buttons.rejected ||  "Apenas requeridos" }} 
            <i class="fa fa-circle-o-notch fa-spin" v-if="loadingAll"></i>
         </button>
         </div>
            
           
      </div>
      <div class="sign-up overlay-text-right-animation-out" id="sign-up">
         <h1>{{ consent.configs.custom.title }}</h1>
         <p>{{ consent.configs.custom.description }}</p>
         <button class="switch-button" @click="back" id="slide-left-button">
         {{ consent.configs.buttons.back }}
         </button>
         
      </div>
   </div>
   <div class="form">

      <!-- {{selected}} -->
      <div class="sign-in" id="sign-in-info" style="display: block" v-if="active">
         <h5>{{ consent.configs.custom.info }}</h5>
         <b-form v-on:submit.prevent>
            <div class="consensu-cookie-list accordion" role="tablist" v-if="version.length > 0">
               <template v-for="(item, idx) in version" >
                  <b-card no-body class="mb-1" :key="idx">
                     <b-card-header header-tag="header" class="p-1 text-left" role="tab"   variant="info">
                        <span class="category-name" v-b-toggle="'accordion-' + idx">{{ item.category.name }} ({{ item.cookies.length }})</span>
                        <div class="form-check form-switch switch">
                           <input class="form-check-input" type="checkbox" 
                            :id="`consensu-category-${item.category._id}`"
                            :value="item.category._id"
                            :disabled="item.category.disabled"
                            :checked="selected[item.category._id].cookies.length === item.cookies.length"
                             @click="toggleCategory(item.category._id, $event.target.checked)"
                           >
                           <label class="form-check-label" :for="`consensu-category-${item.category._id}`"></label>
                        </div>
                     </b-card-header>
                     <b-collapse :id="`accordion-${idx}`" accordion="accordion" role="tabpanel">
                        <b-card-body>
                           <b-card-text class="category-description">{{item.category.description}}</b-card-text>
                           <table class="table table-striped">
                              <thead>
                                 <tr>
                                    <th scope="col">Cookie</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col"></th>
                                 </tr>
                              </thead>
                              <tbody >
                                 <tr v-for="cookie in item.cookies" :key="cookie._id">
                                    <td >
                                       <div class="cookie-name">
                                          <span>{{cookie.name}}</span>
                                          <small>{{cookie.expiration}}</small>
                                       </div>
                                    </td>
                                    <td>{{cookie.description}}</td>
                                    <td>
                                       <div class="form-check form-switch switch">
                                          <input class="form-check-input" type="checkbox"
                                            v-if="selected[item.category._id]"
                                            :value="cookie._id"
                                            v-model="selected[item.category._id].cookies"
                                            :data-cookie-name="cookie.name"
                                            :id="cookie._id"
                                            :disabled="item.category.disabled"
                                            :class="`consensu-category-${item.category._id}-cookie`"
                                          >
                                          <label class="form-check-label" :for="cookie._id"></label>
                                       </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                           
                        </b-card-body>
                     </b-collapse>
                  </b-card>
               </template>
               <p class="info-version">
               <span class="version">
                 <b>Identificação Única:</b> {{fingerprint}}
               </span>
               <span class="version">
                 <b>Versão:</b> {{versionID}}
               </span>
               <span class="lastUpdate">
                 <b>Última Atualização:</b> {{ new Date(version[0].lastUpdate).toLocaleString()}}
               </span>
               </p>
            </div>
            <div class="actions">
               <button class="control-button in" @click="saveCustom">
                  Salvar <i class="fa fa-circle-o-notch fa-spin" v-if="loadingCustom"></i>
               </button>
               <button class="control-button in consensu-mobile" @click="back" id="slide-left-button">VOLTAR</button> 
            </div>
         </b-form>
      </div>
      <div
         class="sign-up form-right-slide-in"
         id="sign-up-info"
         style="display: flex"
         >
         <h1>{{ consent.configs.accept.title }}</h1>
         <p class="small">{{ consent.configs.accept.description }}</p>
         <form id="sign-up-form" v-on:submit.prevent >
            <button class="control-button up" @click="saveAll" title="Nesta opção será dada permissão para todas as categorias">
               {{ consent.configs.buttons.accepted }}
                  <i class="fa fa-circle-o-notch fa-spin" v-if="loadingAll"></i>
               </button>
         </form>
          <center><small> - ou - </small></center>
          <button class="control-button up" type="button" @click="saveRequested"  style="font-size: 12px !important;margin-top: 0px;" title="Nesta opção serão dadas as permissões apenas para a categoria requeridos" >
                  {{ consent.configs.buttons.rejected ||  "Apenas requeridos" }} 
                  <i class="fa fa-circle-o-notch fa-spin" v-if="loadingAll"></i>
               </button>
          <div class="consensu-externals">
              <a
                v-if="consent.configs.privacy.url"
                class="consensu-link"
                :href="consent.configs.privacy.url"
                target="_blank"
                >Política de privacidade</a
              >
              <a
                v-if="consent.configs.terms.url"
                class="consensu-link"
                :href="consent.configs.terms.url"
                target="_blank"
                >Termos de uso</a
              >
              <a
                v-if="consent.configs.optOut.url"
                class="consensu-link"
                :href="consent.configs.optOut.url"
                target="_blank"
                >Optout</a
              >
                <a
                v-if="consent.configs.cookies.url"
                class="consensu-link"
                :href="consent.configs.cookies.url"
                target="_blank"
                >Política de cookies</a
              >
            </div>
            <div class="consensu-powered">
              Powered by
              <a href="https://consensu.io" target="_blank">Consensu.io</a>
            </div>
      </div>
   </div>
   <!-- <buttonCaller></buttonCaller> -->
</div>
</template>

<script>
import mixEvents from "../../mixins/events";
export default {
  name: "box",
  mixins: [mixEvents],
  props:["consent","version"],
  components: {
   //   buttonCaller
  },
  data() {
    return {
      overlayClass: "",
      viewport: {
          360: {
            width: 365,
            height: 500,
          },
          400: {
            width: 400,
            height: 430,
          },
          798: {
            width: 400,
            height: 430,
          },
          800:{
            width: 810,
            height: 430,
          },
        },
    };
  },
  mounted() {
     this.setConfig();
     this.sendEvent("makeConsent", this.consent);
  },
  methods: {
     setConfig(){
      
         // const width  = window.screen.availWidth || document.documentElement.clientWidth || 
         // document.body.clientWidth;
         // const height = window.screen.availHeight|| document.documentElement.clientHeight|| 
         // document.body.clientHeight;

         // console.log(width, height);
         // this.sendEvent("configs", {width, height})
        this.sendEvent("makeViewport", this.viewport)
     },
    custom() {
      this.overlayClass = "open-sign-in";
    },
    back() {
      this.overlayClass = "open-sign-up";
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "style.scss";
</style>
